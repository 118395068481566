<template>
	<div class="auth-wrapper auth-v1 px-2">
		<div class="auth-inner py-2">
			<!-- Forgot Password v1 -->
			<b-card class="mb-0">
				<b-link class="brand-logo">
					<!-- logo -->
					<vuexy-logo />
				</b-link>

				<div id="loading-container" v-if="isLoading">
					<b-spinner
						style="width: 3rem; height: 3rem"
						variant="warning"
					/>
				</div>

				<div v-else-if="showSuccess">
					The reset password email has been sent if the account exist.
				</div>

				<template v-else>
					<b-card-title class="mb-1">
						Forgot Password? 🔒
					</b-card-title>
					<b-card-text class="mb-2">
						Enter your email and we'll send you instructions to
						reset your password
					</b-card-text>

					<!-- form -->
					<validation-observer ref="simpleRules">
						<b-form
							class="auth-forgot-password-form mt-2"
							@submit.prevent="validationForm"
						>
							<!-- email -->
							<b-form-group
								label="Email"
								label-for="forgot-password-email"
							>
								<validation-provider
									#default="{ errors }"
									name="Email"
									rules="required|email"
								>
									<b-form-input
										id="forgot-password-email"
										v-model="userEmail"
										:state="
											errors.length > 0 ? false : null
										"
										name="forgot-password-email"
										placeholder="john@example.com"
									/>
									<small class="text-danger">{{
										errors[0]
									}}</small>
								</validation-provider>
							</b-form-group>

							<!-- submit button -->
							<b-button variant="primary" block type="submit">
								Send reset link
							</b-button>
						</b-form>
					</validation-observer>
				</template>

				<b-card-text class="text-center mt-2">
					<b-link :to="{ name: 'auth-login-v1' }">
						<feather-icon icon="ChevronLeftIcon" /> Back to login
					</b-link>
				</b-card-text>
			</b-card>
			<!-- /Forgot Password v1 -->
		</div>
	</div>
</template>

<script>
	import { ValidationProvider, ValidationObserver } from "vee-validate";
	import VuexyLogo from "@core/layouts/components/Logo.vue";
	import {
		BCard,
		BLink,
		BCardText,
		BCardTitle,
		BFormGroup,
		BFormInput,
		BForm,
		BButton,
		BSpinner,
	} from "bootstrap-vue";
	import { required, email } from "@validations";
	import { fetcher, FetchMethod } from "@/libs/axios";
	import apis from "@configs/apis";
	import { ref, onMounted, computed } from "@vue/composition-api";
	import store from "@/store";

	export default {
		components: {
			VuexyLogo,
			BCard,
			BLink,
			BCardText,
			BCardTitle,
			BFormGroup,
			BFormInput,
			BButton,
			BForm,
			ValidationProvider,
			ValidationObserver,
            BSpinner
		},
		setup(props, ctx) {
			const isLoading = ref(false);
			const showSuccess = ref(false);
			const userEmail = ref("");

			function validationForm() {
				this.$refs.simpleRules.validate().then(async (success) => {
					if (success) {
						isLoading.value = true;

						try {
							const response = await fetcher(
								apis.forgotPassword + "/" + userEmail.value,
								FetchMethod.GET
							);

							showSuccess.value = true;
						} catch (e) {
							store.commit("app/SHOW_ERROR_DIALOG", {
								isOpen: true,
								message: e,
							});
						} finally {
							isLoading.value = false;
						}
					}
				});
			}

			return {
				isLoading,
				userEmail,
				showSuccess,

				email,
				required,
				validationForm,
			};
		},
	};
</script>

<style lang="scss">
	@import "@core/scss/vue/pages/page-auth.scss";

    #loading-container {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 50px;
	}
</style>
